$width: 25vmin;

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

div#root {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.pageLoader {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.circles {
  width: $width;
  height: $width;
  position: relative;
  perspective: 50000px;
  color: #61dafb;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 18.4%;
    height: 18.4%;
    border-radius: 100%;
    background: currentColor;
  }

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: $width / 25 solid;
    border-top: $width / 25 solid transparent;
    border-radius: 100%;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: anim-#{$i} 1s linear infinite;
      }
    }
  }
}

@for $i from 1 through 3 {
  @keyframes anim-#{$i} {
    from {
      transform: rotateZ(360deg / 3 * $i) rotateX(66deg) rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg / 3 * $i) rotateX(66deg) rotateZ(360deg);
    }
  }
}
